import { Box, Grid, Button, Typography, Link } from '@mui/material';

const code = `{
  "id": "659a5cee64921dd01e8c2b96",
  "headline": "Final Bitcoin ETF Application...",
  "link": "https://www.coindesk.com/policy/...",
  "article": "Final Bitcoin ETF Application...",
  "sentiment": {
    "BTC": {
      "avg_neg": 0.0,
      "avg neu": 0.08,
      "avg_pos": 0.92,
      "avg_compound": 0.24
    }
  },
  "published_at": "1704496500.0",
  "scraped_at": "1704496501.0",
  "source": "coindesk"
}
`

const sentiment = `"BTC": {
  "avg_neg": 0.0,
  "avg neu": 0.08,
  "avg_pos": 0.92,
  "avg_compound": 0.24
}
`

const notification = `{
  "published_at": "1704496500.0",
  "scraped_at": "1704496501.0",
}
`

function App() {
  return (
    <Box sx={{ m: 4 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5"
          sx={{
            fontWeight: 'bold',
            color: '#344afb'
          }}
        >
          Instant Signals
        </Typography>
        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
          <Link href='https://commerce.coinbase.com/checkout/1de0efe6-070b-4acd-aedf-a6a655e24dbd' target='_blank'>
            <Button
              variant='contained'
              sx={{
                py: 1,
                px: 2,
                borderRadius: '30px',
                fontWeight: 'bold',
                backgroundColor: '#344afb',
                '&:hover': {
                  backgroundColor: '#344afb'
                },
                display: {
                  xs: 'none',
                  sm: 'block'
                }
              }}
            >Get Access</Button>
          </Link>
          <Link href='mailto:haider@instantsignals.tech' target='_blank'>
            <Button
              variant='outlined'
              sx={{
                ml: 2,
                py: 1,
                px: 2,
                borderRadius: '30px',
                fontWeight: 'bold',
                color: '#344afb',
              }}
            >Contact Us</Button>
          </Link>
        </Box>
      </Box>
      {/* Hero */}
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 16 }}>
        <Grid item >
          <Box sx={{ maxWidth: '600px' }}>
            <Typography variant='h3' >
              Get instant signals from the latest crypto news with sentiment analysis
            </Typography>
            <Link href='https://commerce.coinbase.com/checkout/1de0efe6-070b-4acd-aedf-a6a655e24dbd' target='_blank'>
              <Button
                variant='contained'
                sx={{
                  ml: 'auto',
                  py: 1,
                  px: 2,
                  mt: 2,
                  mb: 4,
                  borderRadius: '30px',
                  fontWeight: 'bold',
                  backgroundColor: '#344afb',
                  '&:hover': {
                    backgroundColor: '#344afb'
                  },
                }}
              >
                Get Access
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item sx={{ border: '1px solid #546084', borderRadius: '10px', px: 2, overflow: 'auto' }}>
          <pre>
            <code>{code}</code>
          </pre>
        </Grid>
      </Grid>
      {/* Fact */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5' sx={{ fontStyle: 'italic', textAlign: 'center', width: '800px' }}>
          "In 2021, the news of Tesla buying Bitcoin was available for 6 minutes before the price moved. That's how inefficient the markets are at understanding information that generated 72 billion dollars in wealth."
        </Typography>
      </Box>
      {/* Feature 1 */}
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 16 }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', maxWidth: '500px' }}>
            <Typography variant='h3' >
              AI powered sentiment analysis
            </Typography>
            <Typography variant='h5' sx={{ mt: 2 }}>
              We use state of the art AI to understand the sentiment of the news
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ border: '1px solid #546084', borderRadius: '10px', px: 4, py: 2, mt: { xs: 4, sm: 0 } }}>
          <pre>
            <code>{sentiment}</code>
          </pre>
        </Grid>
      </Grid>
      {/* Feature 2 */}
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 16 }}>
        <Grid item sx={{ border: '1px solid #546084', borderRadius: '10px', px: 4, py: 2, mr: { xs: 0, sm: 6 } }}>
          <pre>
            <code>{notification}</code>
          </pre>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
          <Box sx={{ width: '100%', maxWidth: '500px' }}>
            <Typography variant='h3' >
              Real time notifications
            </Typography>
            <Typography variant='h5' sx={{ mt: 2 }}>
              We send you real time alerts on email, social media and provided webhooks
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Contact us */}
      <Box>
        <Typography variant='h5' sx={{ textAlign: 'center', my: 16 }}>
          Got any questions or custom requirements? <a href="mailto:haider@instantsignals.tech">Contact us</a>
        </Typography>
      </Box>
      {/* Footer */}
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 16 }}>
          <Typography variant="h5"
            sx={{
              fontWeight: 'bold',
              color: '#344afb'
            }}
          >
            Instant Signals
          </Typography>
          <Link href='https://commerce.coinbase.com/checkout/1de0efe6-070b-4acd-aedf-a6a655e24dbd' target='_blank'>
            <Button
              variant='contained'
              sx={{
                py: 1,
                px: 2,
                ml: 4,
                borderRadius: '30px',
                fontWeight: 'bold',
                backgroundColor: '#344afb',
                '&:hover': {
                  backgroundColor: '#344afb'
                },
              }}
            >Get Access</Button>
          </Link>
        </Box>
        <Typography sx={{ textAlign: 'center', my: 4 }}>
          © {new Date().getFullYear()} Instant Signals
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
